
import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { TbSun, TbMoon, TbPower } from "react-icons/tb";
import AuthContext from "../Shared/AuthContext";

function Navbar() {
  const { token, logout } = useContext(AuthContext);
  const [theme, setTheme] = useState('');

  useEffect(() => {
    Theme();
  }, []);

  const Theme = () => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      setTheme('dark');
    } else {
      document.documentElement.classList.remove('dark')
      setTheme('light');
    }
  }

  const changeTheme = () => {
    localStorage.theme = (localStorage.theme === 'dark') ? 'light' : 'dark';
    setTheme(localStorage.theme);
    Theme();
  }
  // Whenever the user explicitly chooses light mode
  //localStorage.theme = 'light'

  // Whenever the user explicitly chooses dark mode
  //localStorage.theme = 'dark'

  // Whenever the user explicitly chooses to respect the OS preference
  //localStorage.removeItem('theme')


  const isActiveclass = "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500";
  const notActive = "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";
  return (
    <>

      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="https://www.sohorta.pt/wp-content/uploads/2019/05/cropped-cropped-new_logo_pt-3.png" className="h-12" alt="Flowbite Logo" />
          </a>
          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button type="button" className="flex text-sm text-xl bg-white p-1 rounded-full md:me-0 hover:text-green dark:bg-gray-900 dark:text-white " onClick={changeTheme}>
              <span className="sr-only">Open user menu</span>

              {(theme === 'dark') ? <TbSun /> : <TbMoon />}

            </button>

            {token ? <button onClick={logout} className="flex text-sm text-xl bg-white p-1 rounded-full md:me-0 hover:text-green dark:bg-gray-900 dark:text-white "><TbPower /></button> : ""}
          </div>
          <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink to="/" className={({ isActive }) => {
                  return isActive ? isActiveclass : notActive;
                }}
                >Dashboard</NavLink>
              </li>
              <li>
                <NavLink to="/woocommerce" className={({ isActive }) => {
                  return isActive ? isActiveclass : notActive;
                }}>Sohorta.pt</NavLink>

              </li>
              <li>
                <NavLink to="/leroy" className={({ isActive }) => {
                  return isActive ? isActiveclass : notActive;
                }}>Leroy Merlin</NavLink>

              </li>
              <li>
                <NavLink to="/worten" className={({ isActive }) => {
                  return isActive ? isActiveclass : notActive;
                }}>Worten</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>


    </>
  );
}

export default Navbar;