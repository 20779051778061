import axios, { all } from 'axios';
import LZString from 'lz-string';

const API_BASE_URL = process.env.REACT_APP_GLOBAL_API_BASE_URL;
const CONSUMER_SECURITY_KEY_L = process.env.REACT_APP_CONSUMER_SECURITY_KEY;
const CONSUMER_SECURITY_KEY_W = process.env.REACT_APP_CONSUMER_SECURITY_KEY_W;

const fetchPage = async (page, store) => {
  const CONSUMER_SECURITY_KEY = (store == "Worten") ? CONSUMER_SECURITY_KEY_W : CONSUMER_SECURITY_KEY_L;
  try {
    const response = await axios.get(`${API_BASE_URL}/GetOffers?page=${page}&secret_key=${CONSUMER_SECURITY_KEY}&store=${store}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching page:", error);
    throw error;
  }
};

export const fetchAllPages = async (update, store) => {
  if (update) {
    localStorage.removeItem(`MiracleOffers_${store}`);
    localStorage.removeItem(`MiracleOffersPrices_${store}`);
  }

  let currentPage = 0;
  let allData = [];
  let hasMorePages = true;
  let lastPage = 0;
  let totalRecords = 0;
  let totalRecordsPushed = 0;
  if (localStorage.getItem(`MiracleOffers_${store}`)) {
    allData = JSON.parse(LZString.decompress(localStorage.getItem(`MiracleOffers_${store}`)));
  } else {
    while (hasMorePages) {
      const data = await fetchPage(currentPage, store);
      totalRecords = data.total_count;
      if (totalRecordsPushed > totalRecords) {
        hasMorePages = false;
      } else {
        if (data.offers) {
          allData = [...allData, ...data.offers];
          currentPage++;  // Move to the next page
          totalRecordsPushed += 100;
        }
      }
    }
    localStorage.setItem(`MiracleOffers_${store}`, LZString.compress(JSON.stringify(allData)));
  }
  return allData;
};


const fetchPrices = async (fetch, store) => {
  const CONSUMER_SECURITY_KEY = (store == "Worten") ? CONSUMER_SECURITY_KEY_W : CONSUMER_SECURITY_KEY_L;
  try {
    const response = await axios.get(`${API_BASE_URL}/GetPrices?ids=${fetch}&secret_key=${CONSUMER_SECURITY_KEY}&store=${store}`

    );


    return response;
  } catch (error) {
    console.error("Error fetching prices:", error);
    throw error;
  }
};


export const fetchAllPrices = async (update, store) => {

  if (update) {
    localStorage.removeItem(`MiracleOffersPrices_${store}`);
  }

  let allData = [];
  if (localStorage.getItem(`MiracleOffers_${store}`)) {
    let Products = JSON.parse(LZString.decompress(localStorage.getItem(`MiracleOffers_${store}`)));

    const allIdsString = Products.map(item => item.product_sku);

    if (localStorage.getItem(`MiracleOffersPrices_${store}`)) {
      allData = JSON.parse(LZString.decompress(localStorage.getItem(`MiracleOffersPrices_${store}`)));
    } else {

      try {
        var fetched = 50;
        var startFetch = 0;
        var fetchmore = true;
        while (fetchmore) {

          var fetch = allIdsString.slice(startFetch, fetched).join(',');

          const response = await fetchPrices(fetch, store);

          var data = response.data.products;
          allData = [...allData, ...data];

          startFetch = fetched;
          fetched = fetched + 50;

          if (fetched > allIdsString.length) {
            fetched = allIdsString.length;
          }

          if (startFetch === allIdsString.length) {
            fetchmore = false;
          }

        }

        localStorage.setItem(`MiracleOffersPrices_${store}`, LZString.compress(JSON.stringify(allData)));

      } catch (error) {
        console.error("Error fetching page:", error);
        throw error;
      }

    }
  }
  return allData;
}