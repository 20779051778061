function Footer() {
  return (
    <>
      <footer className="sticky bottom-0 left-0 z-20 w-full p-1 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-2 dark:bg-green-800 dark:border-gray-600 dark:text-white">
        <span className="text-sm text-gray-500 sm:text-center dark:text-white">Copyright © {new Date().getFullYear()}{" "}
          <a
            href="https://www.sohorta.pt"
            className="text-white hover:text-gray-400 text-sm font-semibold py-1"
          >
            Sohorta.pt
          </a>
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="#" className="hover:underline me-4 md:me-6">Change Log</a>
          </li>
        </ul>
      </footer>
    </>
  );
}


export default Footer;