import axios from 'axios';
import LZString from 'lz-string';

const API_BASE_URL = process.env.REACT_APP_WOO_API_BASE_URL;
const CONSUMER_KEY = process.env.REACT_APP_CONSUMER_KEY;
const CONSUMER_SECRET = process.env.REACT_APP_CONSUMER_SECRET;

const fetchPage = async (page) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/wp-json/wc/v3/products`, {
      params: {
        offset: page * 100,
        per_page: 100 // Adjust as needed
      },
      headers: {
        'Authorization': `Basic ${btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)}`,
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching page:", error);
    throw error;
  }
};

export const fetchAllPages = async (update) => {
  if (update) {
    localStorage.removeItem("wooData");
  }

  let currentPage = 0;
  let allData = [];
  let hasMorePages = true;
  let lastPage = 0;
  if (localStorage.getItem("wooData")) {
    allData = JSON.parse(LZString.decompress(localStorage.getItem("wooData")));
  } else {

    while (hasMorePages) {
      if (lastPage !== currentPage || currentPage === 0) {
        const data = await fetchPage(currentPage);
        if (data.length === 0) {
          hasMorePages = false;
        } else {
          allData = [...allData, ...data];
          currentPage++;
        }
      }
    }

    var date = new Date().toISOString("ISO8601");
    localStorage.setItem("LastCheckedUpdate", LZString.compress(date));

    localStorage.setItem("wooData", LZString.compress(JSON.stringify(allData)));
  }

  return allData;
};

export const checkForUpdates = async () => {
  try {
    if (localStorage.getItem("LastCheckedUpdate")) {
      var date = LZString.decompress(localStorage.getItem("LastCheckedUpdate"));

      const response = await axios.get(`${API_BASE_URL}/wp-json/wc/v3/products`, {
        params: {
          per_page: 5,
          modified_after: date,
        },
        headers: {
          'Authorization': `Basic ${btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)}`,
        }
      });

      return (response.data.length > 0);
    }
    return false
  } catch (error) {
    console.error("Error fetching page:", error);
    throw error;
  }
}

const fetchCategoryPage = async (page) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/wp-json/wc/v3/products/categories`, {
      params: {
        offset: page * 10,
        per_page: 10
      },
      headers: {
        'Authorization': `Basic ${btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching page:", error);
    throw error;
  }
};

export const fetchAllCategories = async (update) => {
  if (update) {
    localStorage.removeItem("wooCategories");
  }

  let currentPage = 0;
  let allData = [];
  let hasMorePages = true;
  let lastPage = 0;

  if (localStorage.getItem("wooCategories")) {
    allData = JSON.parse(LZString.decompress(localStorage.getItem("wooCategories")));
  } else {
    while (hasMorePages) {
      if (lastPage !== currentPage || currentPage === 0) {
        const data = await fetchCategoryPage(currentPage);
        if (data.length === 0) {
          hasMorePages = false;
        } else {
          allData = [...allData, ...data];
          currentPage++;
        }
      }
    }


    localStorage.setItem("wooCategories", LZString.compress(JSON.stringify(allData)));
  }

  return allData;
};


export const organizeCategories = async () => {

  var categories = await fetchAllCategories();
  const categoryMap = new Map();

  categories.forEach(category => {
    categoryMap.set(category.id, { ...category, children: [] });
  });

  const organizedCategories = [];
  categories.forEach(category => {
    if (category.parent === 0) {
      organizedCategories.push(categoryMap.get(category.id));
    } else {
      const parent = categoryMap.get(category.parent);
      if (parent) {
        parent.children.push(categoryMap.get(category.id));
      }
    }
  });

  return organizedCategories;
};

export const CategoryList = ({ categories, padding }) => {

  return (
    <ul className={padding}>
      {categories.map(category => (
        <li className={(category.parent === 0) ? "" : ""} key={category.id}>
          <span>{category.name}</span>
          {category.children.length > 0 && (
            <CategoryList categories={category.children} padding={"ps-8"} />
          )}
        </li>
      ))}
    </ul>
  );
};



