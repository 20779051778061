import { Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Login from "./Shared/Login";
import Woocomerce from "./Views/Woocomerce";
import LeroyMarketplace from "./Views/LeroyMarketplace";
import Dashboard from "./Views/Dashboard";
import AuthContext from './Shared/AuthContext';
import { jwtDecode } from 'jwt-decode'

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));



  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  if (token) {
    let decodedToken = jwtDecode(token);
    let currentDate = new Date();
    const date = new Date(decodedToken.exp * 1000);
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      logout();
    }
  }

  return (
    <>
      {!token ?
        <Login login={login} />
        :
        <div className="flex flex-col min-h-screen">
          <AuthContext.Provider value={{ token, login, logout }}>
            <Navbar login="true" />

            <div className="overflow-x-auto block min-h-screen bg-white border-gray-200 dark:bg-gray-900 ">
              <div className='max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4'>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/leroy" element={<LeroyMarketplace key={"Leroy"} store={"Leroy"} />} />
                  <Route path="/Worten" element={<LeroyMarketplace key={"Worten"} store={"Worten"} />} />
                  <Route path="/woocommerce" element={<Woocomerce />} />
                </Routes>
              </div>
            </div>
            <Footer />
          </AuthContext.Provider>
        </div>
      }
    </>
  );
}

export default App;
