import React, { useState, useEffect } from 'react';
import { fetchAllPages, checkForUpdates } from '../Shared/apiService';
import Pagination from '../Components/Tables/Pagination'
import { TbReload } from "react-icons/tb";
function Woocomerce() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Number of items per page
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // asc or desc
  var [updates, setUpdates] = useState(false);

  const getData = async (update) => {
    try {
      setLoading(true);
      const result = await fetchAllPages(update);
      setData(result);
      setUpdates(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData(false);
    getUpdates();
  }, []);

  const getUpdates = async () => {
    try {
      const tmpupdates = await checkForUpdates();
      setUpdates(tmpupdates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getUpdates();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // Extract the meta value by key
  const extractMetaValue = (metaData, key) => {
    const meta = metaData.find(meta => meta.key === key);
    return meta ? meta.value : '';
  };

  // Sort data based on column and order
  const sortedData = [...data].sort((a, b) => {
    let aValue, bValue;

    if (sortColumn === '_EAN') {
      aValue = extractMetaValue(a.meta_data, '_EAN');
      bValue = extractMetaValue(b.meta_data, '_EAN');
    } else {
      aValue = a[sortColumn];
      bValue = b[sortColumn];
    }

    if (sortColumn) {
      aValue = a[sortColumn];
      bValue = b[sortColumn];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
    }
    return 0;
  });
  // Filter data based on search term
  const filteredData = sortedData.filter(item => {

    const searchInCategories = item.categories.some(category =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const searchInMetaData = item.meta_data.some(meta => {
      if (typeof meta.value === 'string') {
        return meta.value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      // Handle other types if necessary
      return false;
    });

    return (

      item.id.toString().includes(searchTerm) ||
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.price.toLowerCase().includes(searchTerm.toLowerCase()) ||

      searchInCategories ||
      searchInMetaData
    )
  }
  );
  // Calculate current items to be displayed
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle sorting
  const handleSort = (column) => {
    const order = column === sortColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(order);
  };

  const updateTable = () => {
    setUpdates(false);
    getData(true);

  };

  const gallerySection = currentItems.map((article, i) => {
    return (
      <tr key={i}>
        <td key={`id_${i}`} className="whitespace-nowrap px-2 py-2 font-medium">
          {article.id}
        </td>
        <td key={`name_${i}`} className="px-2 py-2">{article.name}</td>
        <td key={`pricee_${i}`} className="px-2 py-2">{article.price}</td>
        <td key={`category_${i}`} className="px-2 py-2">
          {article.categories.map((category, x) => {
            return (<span key={`categoryname_${i}_${x}`}>{category.name}{x < article.categories.length - 1 ? ', ' : ''}</span>);
          })}
        </td>
        <td key={`EAN_${i}`} className="px-2 py-2">
          {article.meta_data.filter(({ key }) => key === "_EAN").map((meta, y) => {
            return (<span key={`ean_${i}_${y}`}>{meta.value}</span>);
          })}
        </td>
        <td key={`sku_${i}`} className="px-2 py-2">{article.sku}</td>
      </tr>
    );
  });


  return (
    <>

      <div className="py-4 flex  w-full">
        <div className='me-auto bg-white dark:text-white border-gray-200 dark:bg-gray-900' >

          <button className="flex text-sm text-xl bg-white p-1 rounded-full md:me-0 hover:text-green dark:bg-gray-900 dark:text-white" onClick={() => updateTable()}><TbReload /></button>


        </div>
        <div className='ms-auto'>
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"></path>
              </svg>
            </div>
            <input type="text" id="table-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Procurar..."
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
                setCurrentPage(1); // Reset to first page on search
              }} />
          </div>
        </div>

      </div>
      <div className="rounded-lg border border-gray-200 min-w-full">
        <div className="overflow-x-auto rounded-t-lg ">
          <table className="min-w-full divide-y-2 bg-white dark:text-white border-gray-200 dark:bg-gray-900 text-sm">
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
              {updates ? <tr><td colSpan="6" className='bg-green-600 text-center py-2 font-bold text-white'>Alguns artigos com updates no website, clique <b><span className='underline' onClick={() => updateTable()}>aqui</span></b> para actualizar (<small>1 min max.</small>)</td></tr> : ""}
              <tr>
                <th scope="col" className="px-2 py-3" onClick={() => handleSort('id')}>ID</th>
                <th scope="col" className="px-2 py-3 text-left" onClick={() => handleSort('name')}>Name</th>
                <th scope="col" className="px-2 py-3" onClick={() => handleSort('price')}>Price</th>
                <th scope="col" className="px-2 py-3" >Categories</th>
                <th scope="col" className="px-2 py-3" onClick={() => handleSort('_EAN')}>EAN</th>
                <th scope="col" className="px-2 py-3" onClick={() => handleSort('sku')}>SKU</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading ? <tr><td colSpan="6" className='text-center py-10 font-bold'><div className="m-auto border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-green-600" /></td></tr> : gallerySection}

            </tbody>
          </table>
        </div>

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>


    </>
  )
}

export default Woocomerce;