import React, { useState, useEffect } from 'react';
import { fetchAllPages, fetchAllPrices } from '../Shared/apiMirakleService';
import Pagination from '../Components/Tables/Pagination'
import { TbCircleCheck, TbCircleX } from "react-icons/tb";
import { TbReload, TbDevicesDollar } from "react-icons/tb";

function LeroyMarketplace({ store }) {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // asc or desc
  const LinkToStore = (store === "Leroy") ? "https://adeo-marketplace.mirakl.net" : "https://marketplace.worten.pt";
  const getData = async (update) => {
    try {
      setLoading(true);
      const result = await fetchAllPages(update, store);
      setData(result);

      if (update)
        getprices(true);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const getprices = async (update) => {
    try {
      const result = await fetchAllPrices(update, store);

      if (result)
        setPrices(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData(false);
    getprices(false);
  }, []);

  if (!data) {
    return;
  }

  const updateTable = () => {
    getData(true, store);

  };


  const updateTablePrices = () => {
    getprices(true, "Leroy");

  };
  // Sort data based on column and order
  const sortedData = [...data].sort((a, b) => {
    let aValue, bValue;


    aValue = a[sortColumn];
    bValue = b[sortColumn];


    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
    }
    return 0;
  });
  // Filter data based on search term
  const filteredData = sortedData.filter(item => {


    return (
      item.product_sku.toString().includes(searchTerm) ||
      item.offer_id.toString().includes(searchTerm) ||
      item.product_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.price.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  }
  );
  // Calculate current items to be displayed
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle sorting
  const handleSort = (column) => {
    const order = column === sortColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(order);
  };

  // Function to get prices based on product_sku
  function getPricesBySku(jsonData, sku, code_country) {
    var retText = "";
    if (jsonData) {
      const product = jsonData.find(product => product.product_sku === sku);
      if (product) {
        let rethtml = '';


        product.offers.forEach((offer, index) => {
          var storetosearch = '';

          const sortedPrices = offer.all_prices.sort((a, b) => {
            if (a.channel_code === null) return -1;
            if (b.channel_code === null) return 1;
            return a.channel_code.localeCompare(b.channel_code);
          });

          let hasprice = false;
          const shopName = offer.shop_name || `Unknown Shop ${index + 1}`;

          const shopPrice = offer.price || `Unknown Shop ${index + 1}`;

          var priceText = `<strong>${shopName}</strong> ${shopPrice}<br />`;

          var pricesExported = '';
          var offerDefaultPrice = 0;
          var count = 0;
          let lastprice = 0;
          sortedPrices.map((priceInfo) => {
            count++;
            offerDefaultPrice = (priceInfo.channel_code == null) ? `<strong>${shopName}</strong>  ${priceInfo.price}€<br/>` : ``;
            hasprice = (priceInfo.channel_code == code_country) ? true : false
            //hasptprice = (priceInfo.channel_code === code_country) ?  true : false
            pricesExported += (priceInfo.channel_code == code_country) ? `<strong>${shopName}</strong>  ${priceInfo.price}€<br/>` : ``;
            rethtml += (hasprice) ? pricesExported : (code_country === '002') ? offerDefaultPrice : '';
            lastprice = priceInfo.price;
          }).join('');



        });

        return rethtml;
      }
    }
    return [];

  }

  const gallerySection = currentItems.map((article, i) => {

    let ColumnToFetchPricesPT = (store === "Leroy") ? "003" : "WRT_PT_ONLINE";
    let ColumnToFetchPricesES = (store === "Leroy") ? "002" : "WRT_ES_ONLINE";
    let pricesSKUPT = getPricesBySku(prices, article.product_sku, ColumnToFetchPricesPT);
    let pricesSKUES = getPricesBySku(prices, article.product_sku, ColumnToFetchPricesES);
    return (
      <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <td key={`active_${i}`} className="px-2 py-2"><span className='px-5'>{article.active ? <TbCircleCheck color="green" /> : <TbCircleX color="red" />}</span></td>
        <td key={`offer_id_${i}`} scope="row" className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white" ><a href={`${LinkToStore}/mmp/shop/offer/${article.offer_id}`} target='_blank'>{article.offer_id}</a></td>
        <td key={`aproduct_title_${i}`} className="px-2 py-2"><a href={`${LinkToStore}/${article.offer_id}`} target='_blank'>{article.product_title}</a></td>

        <td key={`stock_${i}`} className="px-2 py-2">{article.quantity}</td>
        <td key={`price_${i}`} className="px-2 py-2"><a href={`${LinkToStore}/mmp/shop/offer/${article.offer_id}`} target='_blank'>{article.price}</a></td>
        <td key={`article_product_sku_${i}`} className="px-2 py-2"> <div dangerouslySetInnerHTML={{ __html: pricesSKUPT }} /></td>
        <td key={`article_product_skues_${i}`} className="px-2 py-2"> <div dangerouslySetInnerHTML={{ __html: pricesSKUES }} /></td>
        <td key={`EAN_${i}`} className="px-2 py-2">{article.product_references.filter(({ reference_type }) => reference_type === "EAN").map((ref, y) => {
          return (<span key={`referenc_${y}`}>{ref.reference}</span>);
        })}</td>
        <td key={`product_sku_${i}`} className="px-2 py-2">{article.product_sku}</td>
      </tr>

    );
  });


  return (
    <>
      <div className="py-4 flex  w-full">
        <div className='flex me-auto bg-white dark:text-white border-gray-200 dark:bg-gray-900' >
          <button className="text-sm text-xl bg-white p-1 rounded-full md:me-0 hover:text-green dark:bg-gray-900 dark:text-white" onClick={() => updateTable()}><TbReload /></button>
          <button className="text-sm text-xl bg-white p-1 rounded-full md:me-0 hover:text-green dark:bg-gray-900 dark:text-white" onClick={() => updateTablePrices()}><TbDevicesDollar /></button>
        </div>
        <div className='ms-auto'>
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"></path>
              </svg>
            </div>
            <input type="text" id="table-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Procurar..."
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
                setCurrentPage(1); // Reset to first page on search
              }} />
          </div>
        </div>

      </div>

      <div className="rounded-lg border border-gray-200 min-w-full">
        <div className="overflow-x-auto rounded-t-lg ">
          <table className="min-w-full divide-y-2 bg-white dark:text-white border-gray-200 dark:bg-gray-900 text-sm">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
              <tr>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('active')}>active</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('id')}>ID</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('product_title')}>Name</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('quantity')}>STOCK</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('price')}>Price</th>

                <th scope="col" className="px-6 py-3" onClick={() => handleSort('price')}>PT</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('price')}>ES</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('EAN')}>EAN</th>
                <th scope="col" className="px-6 py-3" onClick={() => handleSort('sku')}>SKU</th>
              </tr>
            </thead>
            <tbody>
              {loading ? <tr><td colSpan="9" className='text-center py-10 font-bold'><div className="m-auto border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-green-600" /></td></tr> : gallerySection}
            </tbody>

          </table>
        </div>

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </>
  )



}

export default LeroyMarketplace;