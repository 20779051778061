import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
const maxpages = Math.ceil(totalItems / itemsPerPage);
  for (let i = 1; i <= maxpages; i++) {
    if (currentPage + 4 >= i && currentPage - 4 <= i)
        pageNumbers.push(i);
  }

  return (
    
    <div className="flex rounded-b-lg border-t border-gray-200 px-4 py-2 bg-white dark:text-white border-gray-200 dark:bg-gray-900">
      <p className='flex basis-1/2 justify-start gap-1 text-xs font-medium'>{totalItems} Artigos encontrados</p>
    <ol className="flex basis-1/2 justify-end gap-1 text-xs font-medium">
        
        <li className={currentPage === 1 ? 'disabled hidden' : ''}>
          <a className='bg-white dark:text-white border-gray-200 dark:bg-gray-900 inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          <span className="sr-only">Prev Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          </a>
        </li>
        {pageNumbers.map(number => (
          <li key={number} >
            <a aria-current="page" className={number === currentPage ? 'block size-8 rounded border-blue-600 bg-blue-600 text-center leading-8 text-white' : 'block size-8 rounded border border-gray-100 bg-white text-center leading-8 text-gray-900'} onClick={() => paginate(number)}>
              {number}
            </a>
          </li>
            
        ))}
        <li className={currentPage === maxpages ? 'disabled hidden' : ''}>
          
          
          <a className='inline-flex  size-8 items-center justify-center rounded border border-gray-100 bg-white dark:text-white border-gray-200 dark:bg-gray-900 text-gray-900 rtl:rotate-180' onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length}>
          <span className="sr-only">Next Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Pagination;