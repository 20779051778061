import React, { useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_GLOBAL_API_BASE_URL;  // Replace with your API's base URL 

const Login = ({ login }) => {
  document.documentElement.classList.add('h-full')
  document.body.classList.add("h-full");
  document.getElementById('root').classList.add("h-full");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    const url = `${API_BASE_URL}/Login`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      } else {
        document.documentElement.classList.remove('h-full')
        document.body.classList.remove("h-full");
        document.getElementById('root').classList.remove("h-full");
        login(data.token)

      }

    } catch (error) {
      setError(error.message);
    }
  };

  return (


    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-17 w-auto" src="https://www.sohorta.pt/wp-content/uploads/2019/05/cropped-cropped-new_logo_pt-3.png?color=indigo&shade=600" alt="Sohorta" />

      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleLogin} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Utilizadxor</label>
            <div className="mt-2">
              <input type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)} id="username" name="username" autoComplete="username" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>

            </div>
            <div className="mt-2">
              <input id="password" type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} name="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            {error && <p style={{ color: "red" }}>{error}</p>}

            <button type="submit" className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
          </div>
        </form>
      </div>
    </div>


  );
};

export default Login;